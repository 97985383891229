import { Rating } from '../store/rating/types';

export function calculateAverageRating(rating: Rating) {
  const { overall, workmanship, punctuality, behavior } = rating;

  const values = [overall, workmanship, punctuality, behavior].filter(value => value);
  const average = values.reduce<number>((total, value) => total + (value || 0), 0) / values.length;

  return average;
}
