import api from '../../api';
import queryParams from '../../utilities/queryParams';

import { Dispatch } from '../types';

import { ActiveReferralCouponAction } from './actionTypes';
import { FetchActiveReferralCouponError } from './errors';
import { ActiveReferralCouponData } from './types';

export function fetchActiveReferralCouponRequest(): ActiveReferralCouponAction {
  return { type: 'FETCH_ACTIVE_REFERRAL_COUPON_REQUEST' };
}

export function fetchActiveReferralCouponSuccess(data: ActiveReferralCouponData): ActiveReferralCouponAction {
  return { type: 'FETCH_ACTIVE_REFERRAL_COUPON_SUCCESS', payload: data };
}

export function fetchActiveReferralCouponFailure(error: FetchActiveReferralCouponError): ActiveReferralCouponAction {
  return { type: 'FETCH_ACTIVE_REFERRAL_COUPON_FAILURE', error };
}

export function fetchActiveReferralCoupon() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchActiveReferralCouponRequest());

      const { customerId } = queryParams;

      if (!customerId) {
        throw new FetchActiveReferralCouponError('MISSING_CUSTOMER_ID');
      }

      const response = await api.backend.get<ActiveReferralCouponData>('/v1/active_referral_coupons', {
        params: { customer_id: customerId },
      });

      dispatch(fetchActiveReferralCouponSuccess(response.data));
    } catch (error) {
      if (error instanceof FetchActiveReferralCouponError) {
        dispatch(fetchActiveReferralCouponFailure(error));
      } else if (error.request || error.response) {
        dispatch(fetchActiveReferralCouponFailure(new FetchActiveReferralCouponError('OTHER')));
      } else {
        throw error;
      }
    }
  };
}
