import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Spinner from '@freska-fi/ui-spinner';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { APIStatus } from '../../store/api/types';

interface Props {
  status: APIStatus;
  messageId: string;
}

const Status: FunctionComponent<Props> = ({ status, messageId }) => {
  const statusIcon = () => {
    switch (status) {
      case 'SUCCESS':
        return <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '64px' }} />;
      case 'FAILURE':
        return <CancelRoundedIcon color="error" sx={{ fontSize: '64px' }} />;
      case 'NOT_ASKED':
      case 'LOADING':
      default:
        return <StyledSpinner size={64} />;
    }
  };

  return (
    <Box textAlign="center">
      {statusIcon()}
      <Typography sx={{ letterSpacing: '-0.2px' }} variant="body2" component="p" pt={2} textAlign="center">
        <FormattedMessage id={messageId} />
      </Typography>
    </Box>
  );
};

const StyledSpinner = styled(Spinner)`
  display: inline-block;
`;

export default Status;
