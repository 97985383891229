import React, { ReactNode } from 'react';
import Box from '@freska-fi/ui-box';
import Card from '@freska-fi/ui-card';

export interface MainCardProps {
  children: ReactNode;
  slim?: boolean;
}

export default function MainCard({ children, slim = false }: MainCardProps) {
  return (
    <Box my={[9, 9, 9, 4]} width="100%" maxWidth={slim ? 664 : 960} mx="auto">
      <Card sx={{ py: [5, 5, 7], px: [3, 3, 12], borderRadius: ['8px', '8px', '20px'] }}>{children}</Card>
    </Box>
  );
}
