export class APIError extends Error {
  status: number;
  errors: string[];

  constructor(status: number, errors: string[] = []) {
    super();
    this.status = status;
    this.errors = errors;
  }
}

export const errorKeys = {
  invalid_token_error: 'invalid_token_error',
  password_strength_error: 'password_strength_error',
  password_too_short: 'password_too_short',
  sequence_numbers_or_characters: 'sequence_numbers_or_characters',
  forbidden_words: 'forbidden_words',
};
