import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

interface Props {
  children: ReactNode;
}

const List = styled('ul')(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  listStyle: 'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export default function ImageList({ children }: Props) {
  return <List>{children}</List>;
}
