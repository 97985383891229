import React from 'react';
import Box from '@freska-fi/ui-box';
import CircularProgress from '@freska-fi/ui-circular-progress';
import Fade from '@freska-fi/ui-fade';

export default function PageLoader() {
  return (
    <Box
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      bgcolor="white"
    >
      <Fade in style={{ transitionDelay: '800ms' }}>
        <CircularProgress color="primary" size={64} />
      </Fade>
    </Box>
  );
}
