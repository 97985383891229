import React from 'react';
import clsx from 'clsx';
import Rating, { RatingProps, IconContainerProps } from '@mui/material/Rating';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@freska-fi/ui-typography';

import RatingEmptyIcon from './RatingEmptyIcon';
import RatingFaceIcon from './RatingFaceIcon';

export interface Props extends RatingProps {
  label: string;
}

export default function RatingInput({ label, value, ...rest }: Props) {
  const theme = useTheme();

  const fillColors = {
    0: theme.palette.background.default,
    1: theme.palette.error.main,
    2: theme.palette.error.light,
    3: theme.palette.warning.main,
    4: theme.palette.info.main,
    5: theme.palette.success.main,
  };

  const CustomSpan = styled('span', { shouldForwardProp: prop => prop !== 'selected' })<{
    selected?: boolean;
  }>(({ selected }) => ({
    '& svg': {
      transform: selected && 'scale(1.7)',
    },
  }));
  function IconContainer({ className, value: index }: IconContainerProps) {
    const selected = !!(value && index === value);
    return (
      <CustomSpan selected={selected} className={clsx(className)}>
        {selected ? (
          <RatingFaceIcon value={value || 0} fontSize="inherit" />
        ) : (
          <RatingEmptyIcon fill={fillColors[value && index < value ? value : 0]} fontSize="inherit" />
        )}
      </CustomSpan>
    );
  }

  return (
    <>
      <Typography variant="overline" sx={{ fontWeight: 900 }} component="p" mb={2}>
        {label}
      </Typography>

      <Rating
        sx={{
          '& .MuiRating-root': {
            marginLeft: -2,
          },
          '& .MuiRating-icon': {
            marginLeft: 2,
            marginRight: 2,
          },
        }}
        name={label}
        size="large"
        value={value}
        IconContainerComponent={IconContainer}
        {...rest}
      />
    </>
  );
}
