import config from '../config';

export function checkoutUrl() {
  return config.checkoutUrl;
}

export function siteUrl(locale: string) {
  return `${config.siteUrl}${locale === 'en' ? '/en' : ''}`;
}

export function termsAndConditionsUrl(locale: string) {
  return config.termsAndConditionsUrl[locale];
}

export function privacyPolicyUrl(locale: string) {
  return config.privacyPolicyUrl[locale];
}

export function freskaAppUrl(locale: string) {
  return config.freskaAppUrl[locale];
}
