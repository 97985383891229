import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';
import { useTheme, styled } from '@mui/material/styles';

import { Rating } from '../../store/rating/types';
import { calculateAverageRating } from '../../utilities/rating';

interface Props {
  rating: Rating;
}

const AverageRatingText = styled('span', { shouldForwardProp: prop => prop !== 'small' })<{ small?: boolean }>(
  ({ theme, small }) => ({
    fontFamily: 'Futura BT',
    fontSize: small ? '25px' : '35px',
    color: theme.palette.primary.main,
    letterSpacing: '1px',
  })
);

export default function AverageRating({ rating }: Props) {
  const theme = useTheme();
  return (
    <>
      <Box display="inline-block" mr={1}>
        <Typography component="span">
          <FormattedMessage id="Your average rating" />:
        </Typography>
      </Box>
      <Box display="inline-block" mr={'4px'}>
        <AverageRatingText>
          <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={2} value={calculateAverageRating(rating)} />
        </AverageRatingText>
      </Box>
      <Box display="inline-block">
        <AverageRatingText theme={theme} small>
          /5
        </AverageRatingText>
      </Box>
    </>
  );
}
