import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';

import App from './components/App';
import LocaleProvider from './i18n/LocaleProvider';
import store from './store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_HOST_ENV || 'development',
  normalizeDepth: 10,
});

Cookies.defaults = { expires: 31 };

ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider>
      <App />
    </LocaleProvider>
  </Provider>,
  document.getElementById('root')
);
