import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Box from '@freska-fi/ui-box';
import Button from '@freska-fi/ui-button';
import Typography from '@freska-fi/ui-typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styled, useTheme } from '@mui/material/styles';

import WaitingImg from '../../assets/images/waiting.png';
import { getBookingRatingWindowDays } from '../../store/business-configuration/selectors';
import { freskaAppUrl } from '../../utilities/urls';

const ImageCustom = styled('img')(({ theme }) => ({
  maxWidth: 560,
  maxHeight: 560,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 240,
    maxHeight: 240,
  },
}));

export default function RateBookingExpiration() {
  const { locale } = useIntl();
  const theme = useTheme();
  const days = useSelector(getBookingRatingWindowDays);

  return (
    <>
      <GlobalStyles styles={{ body: { backgroundColor: theme.palette.common.white } }} />
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        my="auto"
        py={7}
        px={3}
      >
        <Box
          display="flex"
          flex="1"
          justifyContent={['center', 'center', 'flex-end']}
          width="100%"
          pr={[0, 0, 6]}
          pb={[4, 4, 0]}
        >
          <ImageCustom alt="" src={WaitingImg} />
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-start" width="100%" pl={[0, 0, 6]}>
          <Box maxWidth={['auto', 'auto', 360]} textAlign={['center', 'center', 'left']}>
            <Box pb={2}>
              <Typography variant="h2" component="h1">
                <FormattedMessage id="Oh no, the link expired..." />
              </Typography>
            </Box>
            <Box pb={4}>
              <Typography variant="body1">
                {days > 2 ? (
                  <FormattedMessage
                    id="Your link is active for {days} after the cleaning is finished."
                    values={{ days }}
                  />
                ) : (
                  <FormattedMessage
                    id="Your link is active for {hours} after the cleaning is finished."
                    values={{ hours: days * 24 }}
                  />
                )}{' '}
                <FormattedMessage id="Try rating the next one in the Freska app!" values={{ days }} />
              </Typography>
            </Box>

            <Button href={freskaAppUrl(locale)}>
              <FormattedMessage id="Explore the Freska app" />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
