import { Action } from '../types';

import { RatingState } from './types';

export const initialState: RatingState = null;

export default function reducer(state = initialState, action: Action): RatingState {
  switch (action.type) {
    case 'FETCH_RATING_BOOKING_REQUEST':
    case 'FETCH_RATING_BOOKING_FAILURE':
      return null;

    case 'FETCH_RATING_BOOKING_SUCCESS':
      return action.payload.booking.rating
        ? {
            bookingId: action.payload.booking.rating.bookingId,
            approved: action.payload.booking.rating.approved,
            overall: action.payload.booking.rating.overall,
            workmanship: action.payload.booking.rating.workmanship,
            punctuality: action.payload.booking.rating.punctuality,
            behavior: action.payload.booking.rating.behavior,
            comments: action.payload.booking.rating.comments,
            genericComments: action.payload.booking.rating.genericComments,
          }
        : null;

    default:
      return state;
  }
}
