import { AppState } from '../types';

import { ConfigurationName, FeatureName } from './types';

export const getConfiguration = <T>(state: AppState, name: ConfigurationName): T =>
  state.businessConfiguration.configurations[name];

export const getFeatureActive = (state: AppState, name: FeatureName) =>
  state.businessConfiguration.features[name].active;

export const getBookingRatingWindowDays = (state: AppState) =>
  getConfiguration<number>(state, 'ssp.customers.booking_rating_window_days');

export const getCleanerNameEnabled = (state: AppState) => getFeatureActive(state, 'ssp.customers.cleaner_name_visible');
