import { Action } from '../types';

import { ActiveReferralCouponState } from './types';

export const initialState: ActiveReferralCouponState = null;

export default function reducer(state = initialState, action: Action): ActiveReferralCouponState {
  switch (action.type) {
    case 'FETCH_ACTIVE_REFERRAL_COUPON_REQUEST':
    case 'FETCH_ACTIVE_REFERRAL_COUPON_FAILURE':
      return null;

    case 'FETCH_ACTIVE_REFERRAL_COUPON_SUCCESS':
      return {
        code: action.payload.activeReferralCoupon.code,
        amount: parseFloat(action.payload.activeReferralCoupon.amount),
        currency: action.payload.activeReferralCoupon.currency,
        referralBonus: parseFloat(action.payload.activeReferralCoupon.referralBonus),
        referralBonusCurrency: action.payload.activeReferralCoupon.referralBonusCurrency,
        totalDiscountAmount: parseFloat(action.payload.activeReferralCoupon.totalDiscountAmount),
      };
    case 'FETCH_RATING_BOOKING_SUCCESS':
      return action.payload.booking.referralCoupons[0]
        ? {
            code: action.payload.booking.referralCoupons[0].coupon.code,
            amount: parseFloat(action.payload.booking.referralCoupons[0].coupon.amount),
            currency: action.payload.booking.referralCoupons[0].coupon.currency,
            referralBonus: parseFloat(action.payload.booking.referralCoupons[0].referralBonus),
            referralBonusCurrency: action.payload.booking.referralCoupons[0].referralBonusCurrency,
            totalDiscountAmount: parseFloat(action.payload.booking.referralCoupons[0].coupon.totalDiscountAmount),
          }
        : state;
    default:
      return state;
  }
}
