import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function RatingEmptyIcon({ fill, ...rest }: SvgIconProps) {
  return (
    <SvgIcon width="32px" height="31px" viewBox="0 0 32 31" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-612.000000, -401.000000)" fill={fill} fillRule="nonzero">
          <g transform="translate(612.000000, 400.000000)">
            <path d="M7.75992495,31.6855365 C7.04838091,31.6874798 6.36573061,31.4042156 5.86459963,30.8990756 C5.36346866,30.3939355 5.0856528,29.7090499 5.09326558,28.9975439 L5.15726541,21.2002319 L0.517278117,14.9175825 C0.00862767863,14.2236262 -0.134990388,13.3272933 0.131379681,12.5091567 C0.39774975,11.69102 1.04155682,11.0510581 1.86127444,10.7895938 L9.29592074,8.43937798 L13.8256861,2.11761752 L13.8256861,2.11761752 C14.3269563,1.416226 15.1360214,1 15.9981246,1 C16.8602278,1 17.6692929,1.416226 18.1705631,2.11761752 L22.7003285,8.45360017 L30.1349748,10.803816 C30.9573973,11.0630453 31.6037061,11.7039598 31.8698191,12.5241808 C32.1359321,13.3444018 31.9889987,14.2426763 31.4754155,14.9353602 L26.853206,21.2002319 L26.9172058,28.9975439 C26.9237701,29.8597814 26.5135635,30.6721222 25.8157938,31.1786931 C25.1180241,31.6852641 24.2185802,31.8237111 23.400771,31.5504258 L15.9981246,29.082877 L8.6025893,31.5504258 C8.33051855,31.6393494 8.04615832,31.684943 7.75992495,31.6855365 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
