import React, { ReactNode } from 'react';

import Card from '@freska-fi/ui-card';
import { styled } from '@mui/material/styles';

export interface InnerCardProps {
  fullWidth?: boolean;
  children: ReactNode;
}

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
}));

export default function InnerCard({ fullWidth = false, children }: InnerCardProps) {
  return (
    <CustomCard
      sx={{
        width: '100%',
        textAlign: 'center',
        maxWidth: fullWidth ? 'auto' : 560,
        borderRadius: ['8px', '8px', '20px'],
        py: [5, 5, 7],
        px: [3, 3, 12],
        mb: 2,
        mx: 'auto',
      }}
    >
      {children}
    </CustomCard>
  );
}
