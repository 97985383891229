/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@freska-fi/ui-box';

import AppStoreEN from '../../assets/badges/app-store-en.svg';
import AppStoreFI from '../../assets/badges/app-store-fi.svg';
import AppStoreNB from '../../assets/badges/app-store-nb.svg';
import AppStoreSV from '../../assets/badges/app-store-sv.svg';
import GooglePlayEN from '../../assets/badges/google-play-en.svg';
import GooglePlaySV from '../../assets/badges/google-play-sv.svg';
import GooglePlayNB from '../../assets/badges/google-play-nb.svg';
import config from '../../config';

const APP_STORE: Record<string, string> = {
  en: AppStoreEN,
  fi: AppStoreFI,
  nb: AppStoreNB,
  sv: AppStoreSV,
};

const GOOGLE_PLAY: Record<string, string> = {
  en: GooglePlayEN,
  fi: GooglePlayEN, // Stupid Google doesn't provide Finnish badge :(
  nb: GooglePlayNB,
  sv: GooglePlaySV,
};

export default function AppStoreBadges() {
  const { locale } = useIntl();

  return (
    <Box display="flex" justifyContent="center">
      <Box mr={1}>
        <a href={config.appStoreUrl} target="_blank">
          <img src={APP_STORE[locale]} alt="App Store" />
        </a>
      </Box>
      <Box>
        <a href={`${config.googlePlayUrl}&hl=${locale}`} target="_blank">
          <img src={GOOGLE_PLAY[locale]} alt="Google Play" />
        </a>
      </Box>
    </Box>
  );
}
