import { AppState } from '../types';

export const getFetchActiveReferralCouponStatus = (state: AppState) => state.status.fetchActiveReferralCoupon;
export const getFetchActiveReferralCouponError = (state: AppState) => state.status.fetchActiveReferralCouponError;
export const getFetchBusinessConfigurationStatus = (state: AppState) => state.status.fetchBusinessConfiguration;
export const getFetchBusinessConfigurationError = (state: AppState) => state.status.fetchBusinessConfigurationError;
export const getFetchRatingBookingStatus = (state: AppState) => state.status.fetchRatingBooking;
export const getFetchRatingBookingError = (state: AppState) => state.status.fetchRatingBookingError;
export const getSubmitRatingStatus = (state: AppState) => state.status.submitRating;
export const getSubmitRatingError = (state: AppState) => state.status.submitRatingError;
