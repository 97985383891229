import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@freska-fi/ui-css-baseline';
import ThemeProvider, { createFreskaTheme } from '@freska-fi/ui-theme';
import { Router, createHistory, LocationProvider } from '@reach/router';

import FuturaBT from '../../assets/fonts/FuturaBT-CondExtraBlack.otf';
import { fetchBusinessConfiguration } from '../../store/business-configuration/actions';
import { routeChange } from '../../store/router/actions';
import { getFetchBusinessConfigurationStatus } from '../../store/status/selectors';
import { Dispatch } from '../../store/types';
import { trackPage } from '../../utilities/tracking';

import ConfirmAccount from '../ConfirmAccount/ConfirmAccount';
import ConfirmProfileLink from '../ConfirmProfileLink/ConfirmProfileLink';
import RateBooking from '../RateBooking/RateBooking';
import RateBookingConfirmation from '../RateBooking/RateBookingConfirmation';
import ReferralShare from '../ReferralShare/ReferralShare';
import ResetPassword from '../ResetPassword/ResetPassword';

// TODO: Remove "any" after following issue is fixed
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/36198
const history = createHistory(window as any);

const theme = createFreskaTheme();
theme.components = {
  ...theme.components,
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          font-family: 'Futura BT';
          src:  url(${FuturaBT}) 
        }
      `,
  },
};

trackPage();

export default function App() {
  const dispatch = useDispatch<Dispatch>();
  const status = useSelector(getFetchBusinessConfigurationStatus);

  const { locale } = useIntl();

  useEffect(() => {
    dispatch(fetchBusinessConfiguration());
  }, [dispatch]);

  useEffect(() => {
    history.listen(() => dispatch(routeChange()));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <html lang={locale} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline />

        <LocationProvider history={history}>
          {status !== 'NOT_ASKED' && status !== 'LOADING' ? (
            <Router>
              <ConfirmAccount path="/confirm-account/:token" />
              <ConfirmProfileLink path="/confirm-profile-link/:token" />
              <ResetPassword path="/reset-password/:token" />

              <RateBooking path="/rate-booking/:id" />
              <RateBookingConfirmation path="/rate-booking/:id/confirmation" />

              <ReferralShare path="/referral-share" />
            </Router>
          ) : null}
        </LocationProvider>
      </ThemeProvider>
    </>
  );
}
