import React, { ReactNode } from 'react';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';
import { styled } from '@mui/material/styles';

interface Props {
  children: ReactNode;
  imageSrc: string;
}

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '&:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export default function ImageListItem({ children, imageSrc }: Props) {
  return (
    <ListItem>
      <Box mr={3}>
        <img width={64} src={imageSrc} alt="" />
      </Box>

      <Box>
        <Typography variant="subtitle1">{children}</Typography>
      </Box>
    </ListItem>
  );
}
