import React from 'react';
import { FormattedMessage } from 'react-intl';

import BroomSvg from '../../assets/images/broom.svg';
import RocketSvg from '../../assets/images/rocket.svg';
import VFingersSvg from '../../assets/images/v-fingers.svg';
import { ActiveReferralCoupon } from '../../store/active-referral-coupon/types';
import { formatCurrency } from '../../utilities/format';

import ImageList from '../ImageList/ImageList';
import ImageListItem from '../ImageList/ImageListItem';

export interface ReferralShareImageListProps {
  coupon: ActiveReferralCoupon;
}

export default function ReferralShareImageList({ coupon }: ReferralShareImageListProps) {
  const { code, referralBonus, totalDiscountAmount } = coupon;
  return (
    <ImageList>
      <ImageListItem imageSrc={VFingersSvg}>
        <FormattedMessage id="You share your code: {code} and your buddy books a cleaning" values={{ code }} />
      </ImageListItem>
      <ImageListItem imageSrc={BroomSvg}>
        <FormattedMessage
          id="They receive {amount} off in total from their three first cleanings"
          values={{ amount: formatCurrency(totalDiscountAmount) }}
        />
      </ImageListItem>
      <ImageListItem imageSrc={RocketSvg}>
        <FormattedMessage
          id="You earn a {amount} discount on your next cleaning"
          values={{ amount: formatCurrency(referralBonus) }}
        />
      </ImageListItem>
    </ImageList>
  );
}
