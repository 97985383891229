export type FetchRatingBookingErrorType = 'MISSING_TOKEN' | 'OTHER';

export class FetchRatingBookingError extends Error {
  type: FetchRatingBookingErrorType;

  constructor(type: FetchRatingBookingErrorType) {
    super();
    this.type = type;
  }
}
