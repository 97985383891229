import React, { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@freska-fi/ui-box';
import Button from '@freska-fi/ui-button';
import Typography from '@freska-fi/ui-typography';
import Input from '@freska-fi/ui-input';
import Divider from '@material-ui/core/Divider';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';

import { getBooking } from '../../store/booking/selectors';
import { getCleanerNameEnabled } from '../../store/business-configuration/selectors';
import { submitRating } from '../../store/rating/actions';
import { Rating } from '../../store/rating/types';
import { getServiceWorker } from '../../store/service-worker/selectors';
import { getSubmitRatingStatus } from '../../store/status/selectors';
import { Dispatch } from '../../store/types';

import AverageRating from './AverageRating';
import RatingInput from './RatingInput';

type RatingField = 'overall' | 'workmanship' | 'punctuality' | 'behavior';

const RATING_FIELDS: RatingField[] = ['overall', 'workmanship', 'punctuality', 'behavior'];

export default function RatingForm() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch<Dispatch>();
  const booking = useSelector(getBooking);
  const serviceWorker = useSelector(getServiceWorker);
  const status = useSelector(getSubmitRatingStatus);
  const cleanerNameEnabled = useSelector(getCleanerNameEnabled);

  const [rating, setRating] = useState<Rating>({
    bookingId: booking?.id ?? null,
    approved: true,
    overall: null,
    workmanship: null,
    punctuality: null,
    behavior: null,
    comments: null,
    genericComments: null,
  });

  function handleChangeRating(field: keyof Rating) {
    return (_: ChangeEvent<{}>, value: number | null) => {
      setRating({ ...rating, [field]: value });
    };
  }

  function handeChangeComments(field: keyof Rating) {
    return (e: ChangeEvent<HTMLTextAreaElement>) => {
      setRating({ ...rating, [field]: e.target.value });
    };
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    dispatch(submitRating(rating));
  }

  const labels: Record<RatingField, string> = {
    overall: formatMessage({ id: 'What is your overall impression?' }),
    workmanship: formatMessage({ id: 'How was the quality of cleaning?' }),
    punctuality:
      cleanerNameEnabled && serviceWorker
        ? formatMessage({ id: 'Was {name} on time?' }, { name: serviceWorker.firstname })
        : formatMessage({ id: 'Was the cleaner on time?' }),
    behavior:
      cleanerNameEnabled && serviceWorker
        ? formatMessage({ id: "Are you happy with {name}'s behaviour?" }, { name: serviceWorker.firstname })
        : formatMessage({ id: "Are you happy with the cleaner's behaviour?" }),
  };

  const { overall, workmanship, punctuality, behavior } = rating;

  const hasRated = overall || workmanship || punctuality || behavior;

  return (
    <>
      <Box textAlign="center" mb={5}>
        <Typography variant="h2" component="h1" mb={3}>
          <FormattedMessage id="Rate cleaning" />
        </Typography>

        <Typography variant="body1">
          <FormattedMessage
            id="Thanks for choosing Freska! We want to make sure our service met your expectations. Please take a moment to
      review it."
          />
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection={['column', 'row']} justifyContent="space-between" mb={[0, 5]}>
          <Box width="100%" mb={[6, 0]}>
            {RATING_FIELDS.map((field, index) => (
              <Box key={field} mt={index > 0 ? 3 : 0}>
                <ThemeProvider theme={theme}>
                  <RatingInput label={labels[field]} value={rating[field]} onChange={handleChangeRating(field)} />
                </ThemeProvider>
              </Box>
            ))}
          </Box>

          <Box width="100%">
            <Box mb={[3, 5]}>
              <Input
                name="feedback-cleaner"
                label={formatMessage({ id: 'Cleaner feedback' })}
                placeholder={formatMessage({ id: 'How did it go? (visible to cleaner)' })}
                onChange={handeChangeComments('comments')}
                rows={6}
                multiline
                fullWidth
              />
            </Box>
            <Input
              name="feedback-freska"
              label={formatMessage({ id: 'Feedback for Freska' })}
              placeholder={formatMessage({ id: 'How did it go? (visible to the customer care only)' })}
              onChange={handeChangeComments('genericComments')}
              rows={6}
              multiline
              fullWidth
            />
          </Box>
        </Box>

        <Box display={['none', 'block']}>
          <ThemeProvider theme={theme}>
            <Divider />
          </ThemeProvider>
        </Box>

        <Box
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          mt={[3, 5]}
        >
          <Box mb={[2, 0]}>{hasRated && <AverageRating rating={rating} />}</Box>

          <Box display="flex" flexDirection={['column', 'row']} alignItems="center" width={['100%', 'auto']}>
            {!hasRated && (
              <Box mr={[0, 3]} mb={[2, 0]}>
                <Typography variant="body2" color="text.secondary">
                  &#9757; <FormattedMessage id="Start by rating your cleaning" />
                </Typography>
              </Box>
            )}

            <Box width={['100%', 'auto']}>
              <Button
                type="submit"
                loading={status === 'LOADING'}
                disabled={status === 'SUCCESS' || !hasRated}
                width="100%"
                size="large"
                sx={{ px: 3, py: 1.5 }}
              >
                <FormattedMessage id="Send feedback" />
              </Button>

              {status === 'FAILURE' && (
                <Typography mt={2}>
                  <FormattedMessage id="Something went wrong!" />
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}
