import { Dispatch, MiddlewareAPI } from 'redux';
import axios, { AxiosError } from 'axios';
import humps from 'humps';

import { Action, AppState } from '../types';

import { APIError } from './errors';

axios.interceptors.request.use(request => ({
  ...request,
  data: humps.decamelizeKeys(request.data),
}));

axios.interceptors.response.use(response => ({
  ...response,
  data: humps.camelizeKeys(response.data),
}));

function apiError(error: AxiosError): APIError {
  if (error.response) {
    return new APIError(error.response.status, error.response.data.errors);
  } else if (error.request) {
    return new APIError(-1, ['network_error']);
  } else {
    throw error;
  }
}

export default function apiMiddleware(store: MiddlewareAPI<Dispatch<Action>, AppState>) {
  return (next: Dispatch<Action>) => async (action: Action) => {
    if (action.type !== 'API') {
      next(action);
      return;
    }

    const { url, method, data, onRequest, onSuccess, onFailure } = action.payload;

    const dataKey = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    store.dispatch(onRequest());

    try {
      const response = await axios.request({
        baseURL: '/soa',
        url,
        method,
        [dataKey]: data,
      });
      store.dispatch(onSuccess(response.data));
    } catch (error) {
      store.dispatch(onFailure(apiError(error)));
    }
  };
}
