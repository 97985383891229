import { Action } from '../types';

import { StatusState } from './types';

export const initialState: StatusState = {
  fetchActiveReferralCoupon: 'NOT_ASKED',
  fetchActiveReferralCouponError: null,
  fetchBusinessConfiguration: 'NOT_ASKED',
  fetchBusinessConfigurationError: null,
  fetchRatingBooking: 'NOT_ASKED',
  fetchRatingBookingError: null,
  submitRating: 'NOT_ASKED',
  submitRatingError: null,
};

export default function reducer(state = initialState, action: Action): StatusState {
  switch (action.type) {
    case 'FETCH_ACTIVE_REFERRAL_COUPON_REQUEST':
      return { ...state, fetchActiveReferralCoupon: 'LOADING', fetchActiveReferralCouponError: null };
    case 'FETCH_ACTIVE_REFERRAL_COUPON_SUCCESS':
      return { ...state, fetchActiveReferralCoupon: 'SUCCESS', fetchActiveReferralCouponError: null };
    case 'FETCH_ACTIVE_REFERRAL_COUPON_FAILURE':
      return { ...state, fetchActiveReferralCoupon: 'FAILURE', fetchActiveReferralCouponError: action.error };
    case 'FETCH_BUSINESS_CONFIGURATION_REQUEST':
      return { ...state, fetchBusinessConfiguration: 'LOADING', fetchBusinessConfigurationError: null };
    case 'FETCH_BUSINESS_CONFIGURATION_SUCCESS':
      return { ...state, fetchBusinessConfiguration: 'SUCCESS', fetchBusinessConfigurationError: null };
    case 'FETCH_BUSINESS_CONFIGURATION_FAILURE':
      return { ...state, fetchBusinessConfiguration: 'FAILURE', fetchBusinessConfigurationError: action.error };
    case 'FETCH_RATING_BOOKING_REQUEST':
      return { ...state, fetchRatingBooking: 'LOADING', fetchRatingBookingError: null };
    case 'FETCH_RATING_BOOKING_SUCCESS':
      return { ...state, fetchRatingBooking: 'SUCCESS', fetchRatingBookingError: null };
    case 'FETCH_RATING_BOOKING_FAILURE':
      return { ...state, fetchRatingBooking: 'FAILURE', fetchRatingBookingError: action.error };
    case 'SUBMIT_RATING_REQUEST':
      return { ...state, submitRating: 'LOADING', submitRatingError: null };
    case 'SUBMIT_RATING_SUCCESS':
      return { ...state, submitRating: 'SUCCESS', submitRatingError: null };
    case 'SUBMIT_RATING_FAILURE':
      return { ...state, submitRating: 'FAILURE', submitRatingError: action.error };

    // Reset all statuses on route change. Except business configuration that needs to stay
    // to be able to render the whole app.
    case 'ROUTE_CHANGE':
      return {
        ...initialState,
        fetchBusinessConfiguration: state.fetchBusinessConfiguration,
        fetchBusinessConfigurationError: state.fetchBusinessConfigurationError,
      };

    default:
      return state;
  }
}
