import Cookies from 'js-cookie';
import queryString from 'query-string';
import { camelize } from 'humps';

interface QueryParams {
  customerId?: string;
  ratingToken?: string;
}

const queryKeys = ['customer_id', 'rating_token'];
const query = queryString.parse(window.location.search);
const params: QueryParams = {};

queryKeys.forEach(key => {
  const cookieName = `freska_${key}`;
  const value = query[key] || Cookies.get(cookieName);

  if (value) {
    params[camelize(key)] = value;
    Cookies.set(cookieName, value);
  }
});

export default params;
