import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import queryString from 'query-string';
import Box from '@freska-fi/ui-box';
import Button from '@freska-fi/ui-button';
import Typography from '@freska-fi/ui-typography';
import Fade from '@material-ui/core/Fade';
import EmailIcon from '@mui/icons-material/EmailRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';

import { trackReferralShareButtonClicked } from '../../utilities/tracking';

import ReferralShareButton from './ReferralShareButton';

const invitationUrlMapping = {
  fi: {
    en: 'https://www.freska.fi/en/welcome-to-freska',
    fi: 'https://www.freska.fi/tervetuloa-freskalle',
  },
  no: {
    en: 'https://www.freska.no/en/welcome-to-freska',
    nb: 'https://www.freska.no/velkommen-til-freska',
  },
  se: {
    en: 'https://www.freska.se/en/welcome-to-freska',
    sv: 'https://www.freska.se/valkommen-till-freska',
  },
};

export interface ReferralShareButtonGroupProps {
  campaign: string;
  code: string;
  amount: string;
}

const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));

export default function ReferralShareButtonGroup({ campaign, code, amount }: ReferralShareButtonGroupProps) {
  const { locale, formatMessage } = useIntl();

  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (linkCopied) {
      const id = setTimeout(() => setLinkCopied(false), 2000);
      return () => clearTimeout(id);
    }
  }, [linkCopied]);

  const quote = formatMessage(
    { id: 'Book a home cleaning with Freska with the code {code} – get a {amount} discount!' },
    { code, amount }
  );

  const invitationUrl = (source: string) =>
    `${invitationUrlMapping[process.env.REACT_APP_COUNTRY || 'fi'][locale]}?${queryString.stringify({
      c: code,
      utm_campaign: campaign,
      utm_medium: 'invitation',
      utm_source: source,
    })}`;

  return (
    <Box textAlign="center">
      <Typography variant="overline" sx={{ fontWeight: theme => theme.typography.fontWeightBold }} component="p" mb={3}>
        <FormattedMessage id="Send invites" />
      </Typography>

      <Box display="flex" flexDirection={['column', 'row']} justifyContent="center" width="100%" mb={4}>
        <Box mb={[2, 0]} mr={[0, 2]}>
          <FacebookShareButton
            quote={quote}
            url={invitationUrl('facebook')}
            onClick={() => trackReferralShareButtonClicked('Facebook')}
          >
            <ReferralShareButton icon={<FacebookIcon />}>
              <FormattedMessage id="Facebook" />
            </ReferralShareButton>
          </FacebookShareButton>
        </Box>

        <Box mb={[2, 0]} mr={[0, 2]}>
          <WhatsappShareButton
            title={quote}
            separator={`\n`}
            url={invitationUrl('whatsapp')}
            onClick={() => trackReferralShareButtonClicked('WhatsApp')}
          >
            <ReferralShareButton icon={<WhatsAppIcon />}>
              <FormattedMessage id="WhatsApp" />
            </ReferralShareButton>
          </WhatsappShareButton>
        </Box>

        <Box>
          <EmailShareButton
            subject={quote}
            url={invitationUrl('email')}
            /* Using onClick breaks the email share button */
            beforeOnClick={async () => trackReferralShareButtonClicked('Email')}
          >
            <ReferralShareButton icon={<EmailIcon />}>
              <FormattedMessage id="Email" />
            </ReferralShareButton>
          </EmailShareButton>
        </Box>
      </Box>

      <Typography variant="overline" sx={{ fontWeight: theme => theme.typography.fontWeightBold }} component="p" mb={3}>
        <FormattedMessage id="Or copy the link and share anywhere you want!" />
      </Typography>

      <CopyToClipboard
        text={invitationUrl('copy_link')}
        onCopy={() => {
          trackReferralShareButtonClicked('Copy');
          setLinkCopied(true);
        }}
      >
        <CustomButton sx={theme => ({ px: theme.spacing(3), py: theme.spacing(2) })} size="large" color="secondary">
          <Box display="flex" alignItems="center">
            <FileCopyRoundedIcon />
            <Box ml={1}>
              <FormattedMessage id="Copy invitation link" />
            </Box>
          </Box>
        </CustomButton>
      </CopyToClipboard>

      <Fade in={linkCopied}>
        <Box pt={2}>
          <Typography component="div" color="primary" variant="body2">
            <FormattedMessage id="Copied! 😍" />
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
}
