import { APIAction } from '../api/types';
import { APIError } from '../api/errors';

import { AccountAction } from './types';

export const confirmAccount = (token: string): APIAction => ({
  type: 'API',
  payload: {
    url: '/v3/customer-accounts/account-confirmation',
    method: 'POST',
    data: { token },
    onRequest: confirmAccountRequest,
    onSuccess: confirmAccountSuccess,
    onFailure: confirmAccountFailure,
  },
});

export const confirmAccountRequest = (): AccountAction => ({
  type: 'CONFIRM_ACCOUNT_REQUEST',
});

export const confirmAccountSuccess = (): AccountAction => ({
  type: 'CONFIRM_ACCOUNT_SUCCESS',
});

export const confirmAccountFailure = (error: APIError): AccountAction => ({
  type: 'CONFIRM_ACCOUNT_FAILURE',
  error,
});

export const confirmProfileLink = (token: string): APIAction => ({
  type: 'API',
  payload: {
    url: '/v3/customer-accounts/profile-access-confirmation',
    method: 'POST',
    data: { token },
    onRequest: confirmProfileLinkRequest,
    onSuccess: confirmProfileLinkSuccess,
    onFailure: confirmProfileLinkFailure,
  },
});

export const confirmProfileLinkRequest = (): AccountAction => ({
  type: 'CONFIRM_PROFILE_LINK_REQUEST',
});

export const confirmProfileLinkSuccess = (): AccountAction => ({
  type: 'CONFIRM_PROFILE_LINK_SUCCESS',
});

export const confirmProfileLinkFailure = (error: APIError): AccountAction => ({
  type: 'CONFIRM_PROFILE_LINK_FAILURE',
  error,
});

export const resetPassword = (token: string, password: string): APIAction => ({
  type: 'API',
  payload: {
    url: '/v3/customer-accounts/password-reset',
    method: 'POST',
    data: {
      token,
      password,
      passwordConfirmation: password,
    },
    onRequest: resetPasswordRequest,
    onSuccess: resetPasswordSuccess,
    onFailure: resetPasswordFailure,
  },
});

export const resetPasswordRequest = (): AccountAction => ({
  type: 'RESET_PASSWORD_REQUEST',
});

export const resetPasswordSuccess = (): AccountAction => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const resetPasswordFailure = (error: APIError): AccountAction => ({
  type: 'RESET_PASSWORD_FAILURE',
  error,
});
