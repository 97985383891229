import api from '../../api';

import { Dispatch } from '../types';

import { BusinessConfigurationAction } from './actionTypes';
import { BusinessConfigurationData } from './types';
import { FetchBusinessConfigurationError } from './errors';

export function fetchBusinessConfigurationRequest(): BusinessConfigurationAction {
  return { type: 'FETCH_BUSINESS_CONFIGURATION_REQUEST' };
}

export function fetchBusinessConfigurationSuccess(payload: BusinessConfigurationData): BusinessConfigurationAction {
  return { type: 'FETCH_BUSINESS_CONFIGURATION_SUCCESS', payload };
}

export function fetchBusinessConfigurationFailure(error: FetchBusinessConfigurationError): BusinessConfigurationAction {
  return { type: 'FETCH_BUSINESS_CONFIGURATION_FAILURE', error };
}

export function fetchBusinessConfiguration() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchBusinessConfigurationRequest());

      const response = await api.soa.get<BusinessConfigurationData>('/business-configuration');

      dispatch(fetchBusinessConfigurationSuccess(response.data));
    } catch (error) {
      if (error.request || error.response) {
        dispatch(fetchBusinessConfigurationFailure(new FetchBusinessConfigurationError()));
      } else {
        throw error;
      }
    }
  };
}
