import React, { ReactNode, Fragment, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { detectLocale } from '../store/locale/actions';
import { getLocale } from '../store/locale/selectors';
import { Dispatch } from '../store/types';

import messages from './messages';

interface Props {
  children: ReactNode;
}

export default function LocaleProvider({ children }: Props) {
  const dispatch = useDispatch<Dispatch>();
  const locale = useSelector(getLocale);

  useEffect(() => {
    dispatch(detectLocale());
  }, [dispatch]);

  if (!locale) return null;

  return (
    <IntlProvider locale={locale} messages={messages[locale]} textComponent={Fragment}>
      {children}
    </IntlProvider>
  );
}
