export type FetchActiveReferralCouponErrorType = 'MISSING_CUSTOMER_ID' | 'OTHER';

export class FetchActiveReferralCouponError extends Error {
  type: FetchActiveReferralCouponErrorType;

  constructor(type: FetchActiveReferralCouponErrorType) {
    super();
    this.type = type;
  }
}
