import { trackPage } from '../../utilities/tracking';

import { Dispatch } from '../types';

export function routeChange() {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'ROUTE_CHANGE' });
    trackPage();
  };
}
