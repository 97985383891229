import React, { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import Button from '@freska-fi/ui-button';
import Typography from '@freska-fi/ui-typography';
import Input from '@freska-fi/ui-input';
import Box from '@freska-fi/ui-box';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { APIStatus } from '../../api';
import { resetPassword } from '../../store/account/actions';
import { getResetPasswordStatus, getResetPasswordError } from '../../store/account/selectors';
import { ResetPasswordErrorType } from '../../store/account/types';
import { AppState, DispatchProps } from '../../store/types';
import Layout from '../Layout/Layout';
import MainCard from '../MainCard/MainCard';
import Status from '../Status/Status';

interface OwnProps extends RouteComponentProps {
  token?: string;
}

interface StateProps {
  status: APIStatus;
  error: ResetPasswordErrorType | null;
}

type Props = OwnProps & StateProps & DispatchProps;

const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

function ResetPassword({ dispatch, token = '', status, error }: Props) {
  const { formatMessage } = useIntl();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [dirty, setDirty] = useState(true);
  const [valid, setValid] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const changePassword = (value: string) => {
    setDirty(true);
    setPassword(value);
  };

  const changePasswordConfirmation = (value: string) => {
    setDirty(true);
    setPasswordConfirmation(value);
  };

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDirty(false);

    if (password !== passwordConfirmation) {
      setValid(false);
    } else {
      setValid(true);
      dispatch(resetPassword(token, password));
    }
  };

  if (status === 'SUCCESS') {
    return (
      <Layout>
        <MainCard slim>
          <Status
            status={status}
            messageId="Your password has been reset successfully. Login in the app with your new password."
          />
        </MainCard>
      </Layout>
    );
  }

  if (status === 'FAILURE') {
    switch (error) {
      case 'INVALID_TOKEN':
        return (
          <Layout>
            <MainCard slim>
              <Status
                status={status}
                messageId="Your request may have expired. Please log in or request a new password again."
              />
            </MainCard>
          </Layout>
        );
      case 'UNKNOWN':
        return (
          <Layout>
            <MainCard slim>
              <Status status={status} messageId="We ran into a problem during password reset." />
            </MainCard>
          </Layout>
        );
      default:
        break;
    }
  }

  const errorMessageId = (() => {
    if (!dirty) {
      if (!valid) {
        return 'Password and password confirmation do not match.';
      }
      if (status === 'FAILURE') {
        switch (error) {
          case 'WEAK_PASSWORD':
            return 'That password is too weak. Please use a more secure password.';
          case 'PASSWORD_TOO_SHORT':
            return 'Password must have at least 8 characters.';
          case 'SEQUENCE_NUMBER_OR_CHARACTERS':
            return 'Password must not contain number or character rows (example: 123, abc)';
          case 'FORBIDDEN_WORDS':
            return 'Password must not contain name, email or the word freska';
          default:
            break;
        }
      }
    }
  })();

  return (
    <Layout>
      <MainCard slim>
        <Typography variant="body2" component="h1" py={1}>
          <FormattedMessage id="Please create a new password" />
        </Typography>

        <form onSubmit={submit}>
          <Box sx={{ my: 2 }}>
            <Input
              id="password"
              type={isPasswordShown ? 'text' : 'password'}
              label={formatMessage({ id: 'Password' })}
              onChange={e => changePassword(e.target.value)}
              value={password}
              error={!!errorMessageId}
              helperText={!!errorMessageId ? formatMessage({ id: errorMessageId }) : undefined}
              fullWidth
              disabledAdornment={false}
              adornment={
                <IconButton
                  sx={{ mr: 0.25 }}
                  aria-label="Toggle password visibility"
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                  edge="end"
                >
                  {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <Input
              id="password-confirmation"
              type={isPasswordShown ? 'text' : 'password'}
              label={formatMessage({ id: 'Password confirmation' })}
              onChange={e => changePasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
              error={!!errorMessageId}
              helperText={!!errorMessageId ? formatMessage({ id: errorMessageId }) : undefined}
              fullWidth
            />
          </Box>

          <CustomButton
            type="submit"
            loading={status === 'LOADING'}
            disabled={!!errorMessageId || !password.length || !passwordConfirmation.length}
            size="large"
            sx={theme => ({ mt: theme.spacing(2), px: theme.spacing(3), py: theme.spacing(1.5) })}
          >
            <FormattedMessage id="Reset password" />
          </CustomButton>
        </form>
      </MainCard>
    </Layout>
  );
}

function mapStateToProps(state: AppState): StateProps {
  return {
    status: getResetPasswordStatus(state),
    error: getResetPasswordError(state),
  };
}

export default connect(mapStateToProps)(ResetPassword);
