import includes from 'lodash/includes';

import { APIError, errorKeys } from '../api/errors';

import {
  AccountAction,
  AccountState,
  ConfirmAccountState,
  ConfirmAccountErrorType,
  ConfirmProfileLinkErrorType,
  ResetPasswordState,
  ResetPasswordErrorType,
} from './types';

const initialState: AccountState = {
  confirmAccount: { status: 'NOT_ASKED', error: null, errorStatus: null },
  confirmProfileLink: { status: 'NOT_ASKED', error: null, errorStatus: null },
  resetPassword: { status: 'NOT_ASKED', error: null, errorStatus: null },
};

const accountReducer = (state = initialState, action: AccountAction): AccountState => {
  switch (action.type) {
    case 'CONFIRM_ACCOUNT_REQUEST':
    case 'CONFIRM_ACCOUNT_SUCCESS':
    case 'CONFIRM_ACCOUNT_FAILURE':
      return {
        ...state,
        confirmAccount: confirmAccountReducer(state.confirmAccount, action),
      };

    case 'CONFIRM_PROFILE_LINK_REQUEST':
    case 'CONFIRM_PROFILE_LINK_SUCCESS':
    case 'CONFIRM_PROFILE_LINK_FAILURE':
      return {
        ...state,
        confirmProfileLink: confirmProfileLinkReducer(state.confirmProfileLink, action),
      };

    case 'RESET_PASSWORD_REQUEST':
    case 'RESET_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        resetPassword: resetPasswordReducer(state.resetPassword, action),
      };

    default:
      return state;
  }
};

const confirmAccountReducer = (state: ConfirmAccountState, action: AccountAction): ConfirmAccountState => {
  switch (action.type) {
    case 'CONFIRM_ACCOUNT_REQUEST':
      return {
        ...state,
        status: 'LOADING',
        error: null,
        errorStatus: null,
      };
    case 'CONFIRM_ACCOUNT_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
        error: null,
        errorStatus: null,
      };
    case 'CONFIRM_ACCOUNT_FAILURE':
      return {
        ...state,
        status: 'FAILURE',
        error: confirmAccountError(action.error),
        errorStatus: action.error.status,
      };
    default:
      return state;
  }
};

const confirmAccountError = (error: APIError): ConfirmAccountErrorType => {
  if (includes(error.errors, errorKeys.invalid_token_error)) {
    return 'INVALID_TOKEN';
  } else {
    return 'UNKNOWN';
  }
};

const confirmProfileLinkReducer = (state: ConfirmAccountState, action: AccountAction): ConfirmAccountState => {
  switch (action.type) {
    case 'CONFIRM_PROFILE_LINK_REQUEST':
      return {
        ...state,
        status: 'LOADING',
        error: null,
        errorStatus: null,
      };
    case 'CONFIRM_PROFILE_LINK_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
        error: null,
        errorStatus: null,
      };
    case 'CONFIRM_PROFILE_LINK_FAILURE':
      return {
        ...state,
        status: 'FAILURE',
        error: confirmProfileLinkError(action.error),
        errorStatus: action.error.status,
      };
    default:
      return state;
  }
};

const confirmProfileLinkError = (error: APIError): ConfirmProfileLinkErrorType => {
  if (includes(error.errors, errorKeys.invalid_token_error)) {
    return 'INVALID_TOKEN';
  } else {
    return 'UNKNOWN';
  }
};

const resetPasswordReducer = (state: ResetPasswordState, action: AccountAction): ResetPasswordState => {
  switch (action.type) {
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        status: 'LOADING',
        error: null,
        errorStatus: null,
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
        error: null,
        errorStatus: null,
      };
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        status: 'FAILURE',
        error: resetPasswordError(action.error),
        errorStatus: action.error.status,
      };
    default:
      return state;
  }
};

const resetPasswordError = (error: APIError): ResetPasswordErrorType => {
  const { errors } = error;
  if (includes(errors, errorKeys.invalid_token_error)) {
    return 'INVALID_TOKEN';
  } else if (includes(errors, errorKeys.password_strength_error)) {
    return 'WEAK_PASSWORD';
  } else if (includes(errors, errorKeys.password_too_short)) {
    return 'PASSWORD_TOO_SHORT';
  } else if (includes(errors, errorKeys.sequence_numbers_or_characters)) {
    return 'SEQUENCE_NUMBER_OR_CHARACTERS';
  } else if (includes(errors, errorKeys.forbidden_words)) {
    return 'FORBIDDEN_WORDS';
  } else {
    return 'UNKNOWN';
  }
};

export default accountReducer;
