import axios from 'axios';
import humps from 'humps';

const baseURL = (() => {
  switch (process.env.REACT_APP_HOST_ENV) {
    case 'production':
      return 'https://api.freska.io/v3';
    case 'staging':
      return 'https://soa.freska-staging.com/v3';
    default:
      return '/soa/v3';
  }
})();

const soa = axios.create({ baseURL });

soa.interceptors.request.use(req => ({ ...req, data: humps.decamelizeKeys(req.data) }));
soa.interceptors.response.use(res => ({ ...res, data: humps.camelizeKeys(res.data) }));

export default soa;
