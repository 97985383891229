import axios from 'axios';
import humps from 'humps';

const baseURL = (() => {
  switch (process.env.REACT_APP_HOST_ENV) {
    case 'development':
      return '/api';
    case 'production':
      return 'https://api.freska.net';
    default:
      return 'https://api.freska-staging.com';
  }
})();

const backend = axios.create({ baseURL });

backend.defaults.headers.common['Accept'] = 'application/json';
backend.defaults.headers.common['Content-Type'] = 'application/json';

backend.interceptors.request.use(request => ({
  ...request,
  data: humps.decamelizeKeys(request.data),
}));

backend.interceptors.response.use(response => ({
  ...response,
  data: humps.camelizeKeys(response.data),
}));

export default backend;
