import { Action } from '../types';

import { BookingState } from './types';

export const initialState: BookingState = null;

export default function reducer(state = initialState, action: Action): BookingState {
  switch (action.type) {
    case 'FETCH_RATING_BOOKING_REQUEST':
    case 'FETCH_RATING_BOOKING_FAILURE':
      return null;
    case 'FETCH_RATING_BOOKING_SUCCESS':
      return {
        id: action.payload.booking.id,
        token: action.payload.booking.token,
        startTime: action.payload.booking.startTime,
        endTime: action.payload.booking.endTime,
        ratingWindowExpired: action.payload.booking.ratingWindowExpired,
      };
    default:
      return state;
  }
}
