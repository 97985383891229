import includes from 'lodash/includes';
import keys from 'lodash/keys';

import siteConfig from '../../config';

import { Action } from '../types';

import { BusinessConfigurationState, Configurations, Features } from './types';

const MARKET_MAP = {
  fi: 'finland',
  no: 'norway',
  se: 'sweden',
};

export const initialState: BusinessConfigurationState = {
  configurations: {
    'ssp.customers.booking_rating_window_days': 2,
  },
  features: {
    'ssp.customers.cleaner_name_visible': { active: false, variant: null },
  },
};

export default function reducer(state = initialState, action: Action): BusinessConfigurationState {
  switch (action.type) {
    case 'FETCH_BUSINESS_CONFIGURATION_REQUEST':
    case 'FETCH_BUSINESS_CONFIGURATION_FAILURE':
      return initialState;
    case 'FETCH_BUSINESS_CONFIGURATION_SUCCESS':
      return {
        ...state,
        configurations: {
          ...state.configurations,
          ...action.payload.configurations
            .filter(config => includes(keys(initialState.configurations), config.name))
            .reduce((configs, config) => {
              const variant =
                config.variants.find(f => f.market === MARKET_MAP[siteConfig.countryCode]) ||
                config.variants.find(f => f.market === 'freska');

              return { ...configs, [config.name]: variant ? variant.value : null };
            }, {} as Configurations),
        },
        features: {
          ...state.features,
          ...action.payload.features
            .filter(feature => includes(keys(initialState.features), feature.name))
            .reduce((features, feature) => {
              const active =
                includes(feature.activeIn, `freska.${MARKET_MAP[siteConfig.countryCode]}`) ||
                includes(feature.activeIn, 'freska');

              // TODO: Handle feature variants
              return { ...features, [feature.name]: { active, variant: null } };
            }, {} as Features),
        },
      };
    default:
      return state;
  }
}
