import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from '@reach/router';

import config from '../../config';
import { confirmProfileLink } from '../../store/account/actions';
import { getConfirmProfileLinkStatus } from '../../store/account/selectors';
import { APIStatus } from '../../store/api/types';
import { AppState, DispatchProps } from '../../store/types';

import Layout from '../Layout/Layout';
import MainCard from '../MainCard/MainCard';
import Status from '../Status/Status';

interface OwnProps extends RouteComponentProps {
  token?: string;
}

interface StateProps {
  status: APIStatus;
}

type Props = OwnProps & StateProps & DispatchProps;

function ConfirmProfileLink({ dispatch, token, status }: Props) {
  useEffect(() => {
    if (token) {
      dispatch(confirmProfileLink(token));
    } else {
      window.location.href = config.siteUrl;
    }
  }, [dispatch, token]);

  const statusMessageId = () => {
    switch (status) {
      case 'SUCCESS':
        return 'Your email was successfully verified.';
      case 'FAILURE':
        return 'Something went wrong or your email has already been verified.';
      case 'NOT_ASKED':
      case 'LOADING':
      default:
        return 'One second! We are verifying your email...';
    }
  };

  return (
    <Layout>
      <MainCard slim>
        <Status status={status} messageId={statusMessageId()} />
      </MainCard>
    </Layout>
  );
}

const mapStateToProps = (state: AppState): StateProps => ({
  status: getConfirmProfileLinkStatus(state),
});

export default connect(mapStateToProps)(ConfirmProfileLink);
