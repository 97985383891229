import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';

import { fetchRatingBooking } from '../../store/booking/actions';
import { getIsRatingWindowExpired } from '../../store/booking/selectors';
import { getRating } from '../../store/rating/selectors';
import { getFetchRatingBookingStatus, getSubmitRatingStatus } from '../../store/status/selectors';
import { Dispatch } from '../../store/types';
import { trackRatingStarted } from '../../utilities/tracking';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Layout from '../Layout/Layout';
import MainCard from '../MainCard/MainCard';
import PageLoader from '../PageLoader/PageLoader';
import RatingForm from '../RatingForm/RatingForm';

import RateBookingExpiration from './RateBookingExpiration';

export interface RateBookingParams {
  id: string;
}

export interface RateBookingProps extends RouteComponentProps<RateBookingParams> {}

export default function RateBooking({ id, navigate }: RateBookingProps) {
  const dispatch = useDispatch<Dispatch>();
  const fetchStatus = useSelector(getFetchRatingBookingStatus);
  const submitStatus = useSelector(getSubmitRatingStatus);
  const rating = useSelector(getRating);
  const isExpired = useSelector(getIsRatingWindowExpired);

  useEffect(() => {
    // TODO: Handle missing id and token
    if (id) {
      trackRatingStarted();
      dispatch(fetchRatingBooking(id));
    }
  }, [dispatch, id]);

  // Navigate to confirmation if booking is already rated
  useEffect(() => {
    if (fetchStatus === 'SUCCESS' && rating) {
      navigate?.('./confirmation');
    }
  }, [fetchStatus, rating, navigate]);

  // Navigate to confirmation when feedback is submitted
  useEffect(() => {
    if (submitStatus === 'SUCCESS') {
      navigate?.('./confirmation');
    }
  }, [submitStatus, navigate]);

  if (fetchStatus === 'NOT_ASKED' || fetchStatus === 'LOADING' || rating) {
    return <PageLoader />;
  }

  return (
    <Layout>
      {fetchStatus === 'FAILURE' ? (
        <MainCard>
          <ErrorMessage id="Something went wrong!" />
        </MainCard>
      ) : isExpired ? (
        <RateBookingExpiration />
      ) : (
        <MainCard>
          <RatingForm />
        </MainCard>
      )}
    </Layout>
  );
}
