import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import account from './account/reducers';
import activeReferralCoupon from './active-referral-coupon/reducer';
import api from './api/middleware';
import booking from './booking/reducer';
import businessConfiguration from './business-configuration/reducer';
import locale from './locale/reducers';
import rating from './rating/reducer';
import serviceWorker from './service-worker/reducer';
import status from './status';
import { AppState, Action } from './types';

const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
const composeEnhancers =
  process.env.NODE_ENV === 'production' ? compose : windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
  account,
  activeReferralCoupon,
  booking,
  businessConfiguration,
  locale,
  rating,
  serviceWorker,
  status,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export default createStore<AppState, Action, {}, {}>(
  rootReducer,
  composeEnhancers(applyMiddleware(api, thunk), sentryReduxEnhancer)
);
