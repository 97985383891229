import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import Spinner from '@freska-fi/ui-spinner';
import Box from '@freska-fi/ui-box';
import ThemeProvider from '@freska-fi/ui-theme';

import { fetchActiveReferralCoupon } from '../../store/active-referral-coupon/actions';
import { getActiveReferralCoupon } from '../../store/active-referral-coupon/selectors';
import { getFetchActiveReferralCouponStatus } from '../../store/status/selectors';
import { Dispatch } from '../../store/types';
import { formatCurrency } from '../../utilities/format';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import InnerCard from '../InnerCard/InnerCard';
import Layout from '../Layout/Layout';
import MainCard from '../MainCard/MainCard';

import ReferralShareButtonGroup from './ReferralShareButtonGroup';
import ReferralShareIntro from './ReferralShareIntro';
import ReferralShareImageList from './ReferralShareImageList';

export interface ReferralShareContainerProps extends RouteComponentProps {}

// eslint-disable-next-line no-empty-pattern
export default function ReferralShareContainer({}: ReferralShareContainerProps) {
  const dispatch = useDispatch<Dispatch>();

  const status = useSelector(getFetchActiveReferralCouponStatus);
  const referralCoupon = useSelector(getActiveReferralCoupon);

  useEffect(() => {
    dispatch(fetchActiveReferralCoupon());
  }, [dispatch]);

  return (
    <Layout>
      <MainCard>
        {status === 'NOT_ASKED' || status === 'LOADING' ? (
          <Spinner />
        ) : (
          <>
            {referralCoupon ? (
              <ThemeProvider>
                <Box mb={6}>
                  <ReferralShareIntro coupon={referralCoupon} />
                </Box>

                <InnerCard fullWidth>
                  <Box mb={3}>
                    <ReferralShareButtonGroup
                      campaign="referral_share_web"
                      code={referralCoupon.code}
                      amount={formatCurrency(referralCoupon.amount)}
                    />
                  </Box>

                  <ReferralShareImageList coupon={referralCoupon} />
                </InnerCard>
              </ThemeProvider>
            ) : (
              <ErrorMessage id="Something went wrong!" />
            )}
          </>
        )}
      </MainCard>
    </Layout>
  );
}
