import config from '../config';

export function formatNumber(value: number | string) {
  return parseFloat(value.toString()).toLocaleString(process.env.REACT_APP_COUNTRY_CODE, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export function formatCurrency(value: number | string) {
  return `${formatNumber(value)} ${config.currencySign}`;
}
