import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';

import FaceSad from '../../assets/images/face-sad.svg';

interface Props {
  id: string;
}

export default function ErrorMessage({ id }: Props) {
  return (
    <Box textAlign="center" width="100%">
      <Box mb={2}>
        <img width={64} src={FaceSad} alt="" />
      </Box>

      <Typography>
        <FormattedMessage id={id} />
      </Typography>
    </Box>
  );
}
