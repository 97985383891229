import * as Sentry from '@sentry/react';

import api from '../../api';
import { trackRatingSubmitted } from '../../utilities/tracking';

import { getBooking } from '../booking/selectors';
import { AppState, Dispatch } from '../types';

import { RatingAction } from './actionTypes';
import { SubmitRatingError } from './errors';
import { Rating } from './types';

export function submitRatingRequest(): RatingAction {
  return { type: 'SUBMIT_RATING_REQUEST' };
}

export function submitRatingSuccess(): RatingAction {
  return { type: 'SUBMIT_RATING_SUCCESS' };
}

export function submitRatingFailure(error: SubmitRatingError): RatingAction {
  return { type: 'SUBMIT_RATING_FAILURE', error };
}

export function submitRating(rating: Rating) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      dispatch(submitRatingRequest());

      const booking = getBooking(getState());
      const token = booking ? booking.token : undefined;

      await api.backend({
        method: 'POST',
        url: '/v1/ratings',
        headers: { 'X-Booking-Token': token },
        data: rating,
      });

      trackRatingSubmitted();

      dispatch(submitRatingSuccess());
    } catch (error) {
      if (error.request || error.response) {
        Sentry.captureMessage('Submit rating failed', { level: Sentry.Severity.Error, extra: error });
        dispatch(submitRatingFailure(new SubmitRatingError()));
      } else {
        throw error;
      }
    }
  };
}
