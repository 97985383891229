import { Action } from '../types';

import { ServiceWorkerState } from './types';

export const initialState: ServiceWorkerState = null;

export default function reducer(state = initialState, action: Action): ServiceWorkerState {
  switch (action.type) {
    case 'FETCH_RATING_BOOKING_REQUEST':
    case 'FETCH_RATING_BOOKING_FAILURE': {
      return null;
    }
    case 'FETCH_RATING_BOOKING_SUCCESS': {
      return {
        id: action.payload.booking.serviceWorker.id,
        firstname: action.payload.booking.serviceWorker.firstname,
      };
    }
    default: {
      return state;
    }
  }
}
