import { Action } from '../types';

import { LocaleState } from './types';

const initialState: LocaleState = null;

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'CHANGE_LOCALE':
      return action.payload;
    default:
      return state;
  }
}
