import api from '../../api';
import queryParams from '../../utilities/queryParams';

import { Dispatch } from '../types';

import { BookingAction } from './actionTypes';
import { FetchRatingBookingError } from './errors';

export function fetchRatingBookingRequest(): BookingAction {
  return { type: 'FETCH_RATING_BOOKING_REQUEST' };
}

export function fetchRatingBookingSuccess(data: any): BookingAction {
  return { type: 'FETCH_RATING_BOOKING_SUCCESS', payload: data };
}

export function fetchRatingBookingFailure(error: FetchRatingBookingError): BookingAction {
  return { type: 'FETCH_RATING_BOOKING_FAILURE', error };
}

export function fetchRatingBooking(id: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchRatingBookingRequest());

      if (!queryParams.ratingToken) {
        throw new FetchRatingBookingError('MISSING_TOKEN');
      }

      const response = await api.backend.get(`/v1/bookings/${id}`, {
        params: { rating_token: queryParams.ratingToken },
      });

      dispatch(fetchRatingBookingSuccess(response.data));
    } catch (error) {
      if (error instanceof FetchRatingBookingError) {
        dispatch(fetchRatingBookingFailure(error));
      } else if (error.request || error.response) {
        dispatch(fetchRatingBookingFailure(new FetchRatingBookingError('OTHER')));
      } else {
        throw error;
      }
    }
  };
}
