import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props extends SvgIconProps {
  value: number;
}

export default function RatingFaceIcon({ value, ...rest }: Props) {
  switch (value) {
    case 1: {
      return (
        <SvgIcon width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <g fill="#FF6F64">
                <path
                  d="M13.5798687,55.4496889 C12.3346666,55.4530896 11.1400286,54.9573773 10.2630494,54.0733822 C9.38607015,53.1893872 8.89989241,51.9908373 8.91321477,50.7457018 L9.02521446,37.1004059 L0.905236705,26.1057693 C0.0150984376,24.8913458 -0.23623318,23.3227633 0.229914442,21.8910242 C0.696062063,20.459285 1.82272443,19.3393517 3.25723026,18.8817891 L16.2678613,14.7689115 L24.1949507,3.70583066 L24.1949507,3.70583066 C25.0721735,2.4783955 26.4880375,1.75 27.9967181,1.75 C29.5053986,1.75 30.9212626,2.4783955 31.7984854,3.70583066 L39.7255748,14.7938003 L52.7362058,18.9066779 C54.1754452,19.3603292 55.3064856,20.4819297 55.7721834,21.9173164 C56.2378811,23.3527032 55.9807478,24.9246836 55.0819772,26.1368803 L46.9931105,37.1004059 L47.1051102,50.7457018 C47.1165977,52.2546175 46.3987362,53.6762139 45.1776392,54.562713 C43.9565422,55.4492121 42.3825153,55.6914944 40.9513492,55.2132451 L27.9967181,50.8950347 L15.0545313,55.2132451 C14.5784075,55.3688614 14.0807771,55.4486502 13.5798687,55.4496889 Z"
                  id="Path"
                />
              </g>
              <path
                d="M28,33.8333333 C30.3625514,33.8333333 32.2777778,35.7485597 32.2777778,38.1111111 C32.2777778,40.4736625 30.3625514,42.3888889 28,42.3888889 C25.6374486,42.3888889 23.7222222,40.4736625 23.7222222,38.1111111 C23.7222222,35.7485597 25.6374486,33.8333333 28,33.8333333 Z M18.2777778,24.5 C19.5664422,24.5 20.6111111,25.5446689 20.6111111,26.8333333 C20.6111111,28.1219977 19.5664422,29.1666667 18.2777778,29.1666667 C16.9891134,29.1666667 15.9444444,28.1219977 15.9444444,26.8333333 C15.9444444,25.5446689 16.9891134,24.5 18.2777778,24.5 Z M38.5,24.5 C39.7886644,24.5 40.8333333,25.5446689 40.8333333,26.8333333 C40.8333333,28.1219977 39.7886644,29.1666667 38.5,29.1666667 C37.2113356,29.1666667 36.1666667,28.1219977 36.1666667,26.8333333 C36.1666667,25.5446689 37.2113356,24.5 38.5,24.5 Z"
                id="1/5"
                fill="#000000"
              />
            </g>
            <rect id="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="56" height="56" />
          </g>
        </SvgIcon>
      );
    }
    case 2: {
      return (
        <SvgIcon width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <g transform="translate(0.000000, 0.000000)" fill="#FBAD91">
                <path
                  d="M13.5798687,55.4496889 C12.3346666,55.4530896 11.1400286,54.9573773 10.2630494,54.0733822 C9.38607015,53.1893872 8.89989241,51.9908373 8.91321477,50.7457018 L9.02521446,37.1004059 L0.905236705,26.1057693 C0.0150984376,24.8913458 -0.23623318,23.3227633 0.229914442,21.8910242 C0.696062063,20.459285 1.82272443,19.3393517 3.25723026,18.8817891 L16.2678613,14.7689115 L24.1949507,3.70583066 L24.1949507,3.70583066 C25.0721735,2.4783955 26.4880375,1.75 27.9967181,1.75 C29.5053986,1.75 30.9212626,2.4783955 31.7984854,3.70583066 L39.7255748,14.7938003 L52.7362058,18.9066779 C54.1754452,19.3603292 55.3064856,20.4819297 55.7721834,21.9173164 C56.2378811,23.3527032 55.9807478,24.9246836 55.0819772,26.1368803 L46.9931105,37.1004059 L47.1051102,50.7457018 C47.1165977,52.2546175 46.3987362,53.6762139 45.1776392,54.562713 C43.9565422,55.4492121 42.3825153,55.6914944 40.9513492,55.2132451 L27.9967181,50.8950347 L15.0545313,55.2132451 C14.5784075,55.3688614 14.0807771,55.4486502 13.5798687,55.4496889 Z"
                  id="Path"
                />
              </g>
              <path
                d="M27.6111111,29.1666667 C30.7301975,29.1666667 32.2777778,32.0269523 32.2777778,32.9477074 C32.2777778,33.8684625 30.698961,33.1543918 27.6111111,33.1543918 C24.5232612,33.1543918 22.9444444,33.8684625 22.9444444,32.9477074 C22.9444444,32.0269523 24.4920247,29.1666667 27.6111111,29.1666667 Z M17.5,24.5 C18.7886644,24.5 19.8333333,25.5446689 19.8333333,26.8333333 C19.8333333,28.1219977 18.7886644,29.1666667 17.5,29.1666667 C16.2113356,29.1666667 15.1666667,28.1219977 15.1666667,26.8333333 C15.1666667,25.5446689 16.2113356,24.5 17.5,24.5 Z M37.7222222,24.5 C39.0108866,24.5 40.0555556,25.5446689 40.0555556,26.8333333 C40.0555556,28.1219977 39.0108866,29.1666667 37.7222222,29.1666667 C36.4335578,29.1666667 35.3888889,28.1219977 35.3888889,26.8333333 C35.3888889,25.5446689 36.4335578,24.5 37.7222222,24.5 Z"
                id="2/5"
                fill="#000000"
              />
            </g>
          </g>
        </SvgIcon>
      );
    }
    case 3: {
      return (
        <SvgIcon width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <g fill="#F9D853">
                <path
                  d="M13.5798687,55.4496889 C12.3346666,55.4530896 11.1400286,54.9573773 10.2630494,54.0733822 C9.38607015,53.1893872 8.89989241,51.9908373 8.91321477,50.7457018 L9.02521446,37.1004059 L0.905236705,26.1057693 C0.0150984376,24.8913458 -0.23623318,23.3227633 0.229914442,21.8910242 C0.696062063,20.459285 1.82272443,19.3393517 3.25723026,18.8817891 L16.2678613,14.7689115 L24.1949507,3.70583066 L24.1949507,3.70583066 C25.0721735,2.4783955 26.4880375,1.75 27.9967181,1.75 C29.5053986,1.75 30.9212626,2.4783955 31.7984854,3.70583066 L39.7255748,14.7938003 L52.7362058,18.9066779 C54.1754452,19.3603292 55.3064856,20.4819297 55.7721834,21.9173164 C56.2378811,23.3527032 55.9807478,24.9246836 55.0819772,26.1368803 L46.9931105,37.1004059 L47.1051102,50.7457018 C47.1165977,52.2546175 46.3987362,53.6762139 45.1776392,54.562713 C43.9565422,55.4492121 42.3825153,55.6914944 40.9513492,55.2132451 L27.9967181,50.8950347 L15.0545313,55.2132451 C14.5784075,55.3688614 14.0807771,55.4486502 13.5798687,55.4496889 Z"
                  id="Path"
                />
              </g>
              <path
                d="M30.5666667,36.0111111 C30.9962215,36.0111111 31.3444444,36.3593341 31.3444444,36.7888889 C31.3444444,37.2184437 30.9962215,37.5666667 30.5666667,37.5666667 L30.5666667,37.5666667 L24.9666667,37.5666667 C24.5371119,37.5666667 24.1888889,37.2184437 24.1888889,36.7888889 C24.1888889,36.3593341 24.5371119,36.0111111 24.9666667,36.0111111 L24.9666667,36.0111111 Z M17.5,24.5 C18.7886644,24.5 19.8333333,25.5446689 19.8333333,26.8333333 C19.8333333,28.1219977 18.7886644,29.1666667 17.5,29.1666667 C16.2113356,29.1666667 15.1666667,28.1219977 15.1666667,26.8333333 C15.1666667,25.5446689 16.2113356,24.5 17.5,24.5 Z M37.7222222,24.5 C39.0108866,24.5 40.0555556,25.5446689 40.0555556,26.8333333 C40.0555556,28.1219977 39.0108866,29.1666667 37.7222222,29.1666667 C36.4335578,29.1666667 35.3888889,28.1219977 35.3888889,26.8333333 C35.3888889,25.5446689 36.4335578,24.5 37.7222222,24.5 Z"
                id="3/5"
                fill="#000000"
              />
            </g>
          </g>
        </SvgIcon>
      );
    }
    case 4: {
      return (
        <SvgIcon width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <g fill="#B6DFEC">
                <path
                  d="M13.5798687,55.4496889 C12.3346666,55.4530896 11.1400286,54.9573773 10.2630494,54.0733822 C9.38607015,53.1893872 8.89989241,51.9908373 8.91321477,50.7457018 L9.02521446,37.1004059 L0.905236705,26.1057693 C0.0150984376,24.8913458 -0.23623318,23.3227633 0.229914442,21.8910242 C0.696062063,20.459285 1.82272443,19.3393517 3.25723026,18.8817891 L16.2678613,14.7689115 L24.1949507,3.70583066 L24.1949507,3.70583066 C25.0721735,2.4783955 26.4880375,1.75 27.9967181,1.75 C29.5053986,1.75 30.9212626,2.4783955 31.7984854,3.70583066 L39.7255748,14.7938003 L52.7362058,18.9066779 C54.1754452,19.3603292 55.3064856,20.4819297 55.7721834,21.9173164 C56.2378811,23.3527032 55.9807478,24.9246836 55.0819772,26.1368803 L46.9931105,37.1004059 L47.1051102,50.7457018 C47.1165977,52.2546175 46.3987362,53.6762139 45.1776392,54.562713 C43.9565422,55.4492121 42.3825153,55.6914944 40.9513492,55.2132451 L27.9967181,50.8950347 L15.0545313,55.2132451 C14.5784075,55.3688614 14.0807771,55.4486502 13.5798687,55.4496889 Z"
                  id="Path"
                />
              </g>
              <path
                d="M32.1318357,33.87905 C32.4988914,33.6559191 32.9773322,33.7725931 33.2004632,34.1396489 C33.4235941,34.5067046 33.30692,34.9851454 32.9398643,35.2082764 C31.4469508,36.1158095 29.6654745,36.5658854 27.6101296,36.5658854 C25.5547848,36.5658854 23.7733084,36.1158095 22.2803949,35.2082764 C21.9133392,34.9851454 21.7966651,34.5067046 22.019796,34.1396489 C22.242927,33.7725931 22.7213678,33.6559191 23.0884236,33.87905 C24.325031,34.6307762 25.8273683,35.0103299 27.6101296,35.0103299 C29.3928909,35.0103299 30.8952282,34.6307762 32.1318357,33.87905 Z M17.5,24.5 C18.7886644,24.5 19.8333333,25.5446689 19.8333333,26.8333333 C19.8333333,28.1219977 18.7886644,29.1666667 17.5,29.1666667 C16.2113356,29.1666667 15.1666667,28.1219977 15.1666667,26.8333333 C15.1666667,25.5446689 16.2113356,24.5 17.5,24.5 Z M37.7222222,24.5 C39.0108866,24.5 40.0555556,25.5446689 40.0555556,26.8333333 C40.0555556,28.1219977 39.0108866,29.1666667 37.7222222,29.1666667 C36.4335578,29.1666667 35.3888889,28.1219977 35.3888889,26.8333333 C35.3888889,25.5446689 36.4335578,24.5 37.7222222,24.5 Z"
                id="4/5"
                fill="#000000"
              />
            </g>
          </g>
        </SvgIcon>
      );
    }
    case 5: {
      return (
        <SvgIcon width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <g fill="#76D2A0">
                <path
                  d="M13.5798687,55.4496889 C12.3346666,55.4530896 11.1400286,54.9573773 10.2630494,54.0733822 C9.38607015,53.1893872 8.89989241,51.9908373 8.91321477,50.7457018 L9.02521446,37.1004059 L0.905236705,26.1057693 C0.0150984376,24.8913458 -0.23623318,23.3227633 0.229914442,21.8910242 C0.696062063,20.459285 1.82272443,19.3393517 3.25723026,18.8817891 L16.2678613,14.7689115 L24.1949507,3.70583066 L24.1949507,3.70583066 C25.0721735,2.4783955 26.4880375,1.75 27.9967181,1.75 C29.5053986,1.75 30.9212626,2.4783955 31.7984854,3.70583066 L39.7255748,14.7938003 L52.7362058,18.9066779 C54.1754452,19.3603292 55.3064856,20.4819297 55.7721834,21.9173164 C56.2378811,23.3527032 55.9807478,24.9246836 55.0819772,26.1368803 L46.9931105,37.1004059 L47.1051102,50.7457018 C47.1165977,52.2546175 46.3987362,53.6762139 45.1776392,54.562713 C43.9565422,55.4492121 42.3825153,55.6914944 40.9513492,55.2132451 L27.9967181,50.8950347 L15.0545313,55.2132451 C14.5784075,55.3688614 14.0807771,55.4486502 13.5798687,55.4496889 Z"
                  id="Path"
                />
              </g>
              <path
                d="M32.0324768,30.722376 C32.482767,30.7160519 32.8531741,31.0591065 32.8598043,31.48861 C32.9140468,35.002439 31.2788766,36.9444444 28.1151886,36.9444444 C24.9967616,36.9444444 23.1638116,35.0643948 22.7551472,31.5866949 C22.7049849,31.159818 23.0271203,30.7749785 23.4746568,30.7271318 C23.9221933,30.6792851 24.3256579,30.9865499 24.3758202,31.4134268 C24.6983208,34.1578796 25.8985074,35.3889063 28.1151886,35.3889063 C30.2866089,35.3889063 31.2709675,34.2198354 31.2291594,31.5115117 C31.2225292,31.0820082 31.5821867,30.7287002 32.0324768,30.722376 Z M18.0833333,24.5 C19.3719977,24.5 20.4166667,25.5446689 20.4166667,26.8333333 C20.4166667,28.1219977 19.3719977,29.1666667 18.0833333,29.1666667 C16.7946689,29.1666667 15.75,28.1219977 15.75,26.8333333 C15.75,25.5446689 16.7946689,24.5 18.0833333,24.5 Z M38.3055556,24.5 C39.59422,24.5 40.6388889,25.5446689 40.6388889,26.8333333 C40.6388889,28.1219977 39.59422,29.1666667 38.3055556,29.1666667 C37.0168911,29.1666667 35.9722222,28.1219977 35.9722222,26.8333333 C35.9722222,25.5446689 37.0168911,24.5 38.3055556,24.5 Z"
                id="Combined-Shape"
                fill="#000000"
              />
            </g>
          </g>
        </SvgIcon>
      );
    }
    default:
      return null;
  }
}
