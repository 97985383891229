import { Config } from './index';

const config: Config = {
  countryCode: 'fi',
  defaultLocale: 'fi',
  currencySign: '€',
  checkoutUrl: 'https://tilaus.freska.fi',
  siteUrl: 'https://www.freska.fi',
  appStoreUrl: 'https://itunes.apple.com/fi/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',
  termsAndConditionsUrl: {
    en: 'https://www.freska.fi/en/terms-and-conditions',
    fi: 'https://www.freska.fi/tilausehdot',
  },
  privacyPolicyUrl: {
    en: 'https://www.freska.fi/en/privacy-policy',
    fi: 'https://www.freska.fi/tietosuojaseloste',
  },
  freskaAppUrl: {
    en: 'https://www.freska.fi/en/freska-app',
    fi: 'https://www.freska.fi/freska-sovellus',
  },
};

export default config;
