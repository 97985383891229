import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';

import AppSvg from '../../assets/images/app.svg';

import InnerCard from '../InnerCard/InnerCard';

import AppCardBadges from './AppCardBadges';

export default function AppCard() {
  return (
    <InnerCard>
      <Box mb={3}>
        <img src={AppSvg} alt="" />
      </Box>

      <Typography variant="h1" mb={2}>
        <FormattedMessage id="Try rating your next cleaning in the app!" />
      </Typography>

      <Typography>✔</Typography>
      <Typography mb={1}>
        <FormattedMessage id="View and manage your subscription" />
      </Typography>

      <Typography>✔</Typography>
      <Typography mb={1}>
        <FormattedMessage id="Share your referral code and earn Freska credit" />
      </Typography>

      <Typography>✔</Typography>
      <Typography mb={5}>
        <FormattedMessage id="Enjoy our superb customer service" />
      </Typography>

      <AppCardBadges />
    </InnerCard>
  );
}
