import React, { useEffect } from 'react';
import Box from '@freska-fi/ui-box';

import { ActiveReferralCoupon } from '../../store/active-referral-coupon/types';
import { formatCurrency } from '../../utilities/format';
import { trackReferralShareCardSeen } from '../../utilities/tracking';

import InnerCard from '../InnerCard/InnerCard';
import ReferralShareButtonGroup from './ReferralShareButtonGroup';
import ReferralShareImageList from './ReferralShareImageList';
import ReferralShareIntro from './ReferralShareIntro';

export interface ReferralShareCardProps {
  campaign: string;
  coupon: ActiveReferralCoupon;
}

export default function ReferralShareCard({ campaign, coupon }: ReferralShareCardProps) {
  useEffect(() => trackReferralShareCardSeen(), []);

  return (
    <InnerCard fullWidth>
      <Box mb={4}>
        <ReferralShareIntro coupon={coupon} />
      </Box>

      <Box mb={3}>
        <ReferralShareButtonGroup campaign={campaign} code={coupon.code} amount={formatCurrency(coupon.amount)} />
      </Box>

      <ReferralShareImageList coupon={coupon} />
    </InnerCard>
  );
}
