import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@freska-fi/ui-box';
import Typography from '@freska-fi/ui-typography';
import ThemeProvider from '@freska-fi/ui-theme';

import DiscountSvg from '../../assets/images/discount.svg';
import { ActiveReferralCoupon } from '../../store/active-referral-coupon/types';
import { formatCurrency } from '../../utilities/format';

export interface ReferralShareIntroProps {
  coupon: ActiveReferralCoupon;
}

export default function ReferralShareIntro({ coupon }: ReferralShareIntroProps) {
  return (
    <ThemeProvider>
      <Box textAlign="center">
        <Box mb={3}>
          <img src={DiscountSvg} alt="" />
        </Box>

        <Box mb={2}>
          <Typography component="h1" variant="h2">
            <FormattedMessage
              id="Psst, want to earn {amount}?"
              values={{ amount: formatCurrency(coupon.referralBonus) }}
            />
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1">
            <FormattedMessage
              id="Remember about our referral program? Invite your friends to a happier everyday life and get {referralAmount} for each of them."
              values={{
                referralAmount: formatCurrency(coupon.referralBonus),
                discountAmount: formatCurrency(coupon.amount),
              }}
            />
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
