import Cookies from 'js-cookie';
import queryString from 'query-string';
import includes from 'lodash/includes';

import config from '../../config';

import { Dispatch } from '../types';

import { Locale, LocaleAction } from './types';

const LOCALES = ['en', 'fi', 'nb', 'sv'];
const COOKIE_NAME = 'freska_lng';

export function detectLocale() {
  return (dispatch: Dispatch) => {
    const query = queryString.parse(window.location.search);
    const locale = query.lng || Cookies.get(COOKIE_NAME);

    if (includes(LOCALES, locale)) {
      dispatch(changeLocale(locale as Locale));
    } else {
      dispatch(changeLocale(config.defaultLocale));
    }
  };
}

export function changeLocale(locale: Locale): LocaleAction {
  Cookies.set(COOKIE_NAME, locale);

  return { type: 'CHANGE_LOCALE', payload: locale };
}
