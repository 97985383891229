import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@freska-fi/ui-typography';
import { styled } from '@mui/material/styles';
interface Props {
  icon: ReactNode;
  children: ReactNode;
}

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export default function ShareButton({ icon, children }: Props) {
  return (
    <CustomBox
      sx={{
        position: 'relative',
        display: 'inline-block',
        minWidth: '96px',
        padding: '16px 24px',
        textAlign: 'center',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        color: theme => theme.palette.common.white,
        border: '1px solid transparent',
        borderRadius: theme => theme.spacing(4),
        boxShadow: '0 7px 14px 0 rgba(0, 5, 82, 0.08)',
        cursor: 'pointer',
        userSelect: 'none',
        outline: 'none',
        '&:focus, &:hover': {
          boxShadow: '0 12px 14px 0 rgba(0, 5, 82, 0.1)',
          opacity: 0.8,
        },
        '&:active': {
          boxShadow: 'none',
        },
      }}
    >
      <Box display="flex" alignItems="center">
        {icon}
        <Typography
          sx={{ fontWeight: theme => theme.typography.button.fontWeight }}
          component="span"
          color="white"
          variant="body1"
          ml={1}
        >
          {children}
        </Typography>
      </Box>
    </CustomBox>
  );
}
